import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Pie } from 'react-chartjs-2';
import wheelCenter from '../../assets/icons/wheel-center.svg';
import wheelIndicator from '../../assets/icons/wheel-indicator.svg';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const rotationValues = [
  { minDegree: 0, maxDegree: 45, index: 1 },
  { minDegree: 46, maxDegree: 90, index: 2 },
  { minDegree: 91, maxDegree: 135, index: 3 },
  { minDegree: 136, maxDegree: 180, index: 4 },
  { minDegree: 181, maxDegree: 225, index: 5 },
  { minDegree: 226, maxDegree: 270, index: 6 },
  { minDegree: 271, maxDegree: 315, index: 7 },
  { minDegree: 316, maxDegree: 360, index: 8 },
];

export default function Wheel({winnerSlot, startWheel, handleFinishWheelSpin, currentLabels}) {
  const [chartData, setChartData] = useState({
    labels: [1,2,3,4,5,6,7,8].reverse(),
    datasets: [
      {
        data: [16,16,16,16,16,16,16,16],
        backgroundColor: [
          '#a51d5d',
          '#f49c26',
          '#b46926',
          '#ed7443',
          '#b25237',
          '#e95c54',
          '#ab324a',
          '#e13a6c',
        ],
        borderColor: [
          '#a51d5d',
          '#f49c26',
          '#b46926',
          '#ed7443',
          '#b25237',
          '#e95c54',
          '#ab324a',
          '#e13a6c',
        ],
      }
    ],
  });
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    animation: { duration: 0 },
    plugins: {
      tooltip: false,
      scale: 0.5,
      legend: { display: false },
      datalabels: {
        color: 'white',
        font: {
          size: 20,
          // weight: 'regular'
        },
        anchor: 'center',
        clamp: true,
        align: 'center',
        rotation: (ctx) => {
          if (chartRef.current?.options.rotation) {
            return chartRef.current?.options.rotation - 22.5 - 45 + ctx.dataIndex * 45
          } else {
            return -22.5 - 45 + ctx.dataIndex * 45;
          }
        },
        formatter: (value, ctx) => {
          return ctx.chart.data.labels[ctx.dataIndex];
        }
      }
    },
  });
  const randomNumberInRange = (min, max) => Math.random() * (max - min) + min;
  const chartRef = useRef(null);  

  const handleWheelSpin = () => {
    // disable button
    let count = 0;
    let resultValue = 501;
    const minValue = rotationValues.filter(pie => pie.index === winnerSlot)[0].minDegree;
    const maxValue = rotationValues.filter(pie => pie.index === winnerSlot)[0].maxDegree;
    const randDegree = Math.floor(randomNumberInRange(minValue, maxValue));
    const testRandDegree = randDegree - 10 >= minValue ?  randDegree : randDegree + 10 <= maxValue ? randDegree : randDegree + 10;
    let rotationInterval = setInterval(() => {
      setChartOptions(prev => {
        return {
          ...prev,
          rotation: chartRef?.current.options.rotation + resultValue
        }
      });
      if (chartRef?.current.options.rotation >= 360 && resultValue > 5) {
        count += 1;
        resultValue -= 5;
        setChartOptions(prev => {
          return {
            ...prev,
            rotation: chartRef?.current.options.rotation - 360
          }
        })
      } else if (count > 90 && chartRef?.current.options.rotation === testRandDegree) {
        handleFinishWheelSpin();
        clearInterval(rotationInterval);
      }
    },45)
  }

  useEffect(() => {
    if (startWheel) {
      handleWheelSpin();
    }
  }, [startWheel])
  
  useEffect(() => {
    if (currentLabels) {
      setChartData(prev => {
        return {...prev, labels: currentLabels}
      })
    }
  },[currentLabels])

  return (
    <div className={styles['wheel']}>
      <Pie
        options={chartOptions}
        ref={chartRef}
        data={chartData}
      />
      <img src={wheelCenter} className={[styles['wheel-center'], startWheel ? styles['wheel-center-animation'] : ''].join(' ')} alt='kasbana logo'/>
      <img src={wheelIndicator} className={styles['wheel-indicator']} alt='wheel indicator'/>
    </div>
  )
}
