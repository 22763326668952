import styles from './index.module.scss';
import Ticket from '../../components/ticket';
import { SwiperSlide } from 'swiper/react';
import AutoSlider from '../../components/slider/autoSlider';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { checkout, getPaymentMethods } from '../../services/checkoutService';
import Radio from '../../components/radio';
import { addOrder } from '../../store/slices/orderSlice';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import Button from '../../components/formButton';
import { retriveProfile } from '../../services/profileService';
import { setProfile } from '../../store/slices/userSlice';
import Loader from '../../components/loader';
import { useWidth } from '../../hooks/useWidth';
import { useTranslation } from 'react-i18next';

export function Checkout() {
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector(state => state.order);
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);
  const isMobile = useWidth()

  const handleGetPaymentMethods = async () => {
    setLoading(true);
    const response = await getPaymentMethods();
    if (response.status === 200) {
      setPaymentMethods(response.data);
      setSelectedPayment('topup')
    }
    setLoading(false);
  }
  const handlePaymentSelect = (slug) => {
    setSelectedPayment(slug);
  }

  const handleCheckout = async () => {
    setLoading(true);
    const payload = {
      is_web: true,
      order_id: order.order_id,
      payment_method: selectedPayment
    }
    const response = await checkout(payload);
    if (response.status === 200) {
      if (selectedPayment !== 'topup') {
        window.location.href = response.data.iframe;
        dispatch(addOrder(null));
      } else {
        navigate(`/payment-status?success=true&pending=false&wheel=${response.data.wheel_id}`)
      }
    } else {
      dispatch(setSnackbar({
        display: true,
        variant: 'error',
        message: response.response.data.message
      }))
    }
    setLoading(false);
  }

  const getProfile = async () => {
    setLoading(true);
    const response = await retriveProfile();
    if (response.status === 200) {
      dispatch(setProfile(response.data))
    } else {
      //handle error
    }
    setLoading(false);
  };
  
  useEffect(() => {
    getProfile();
    handleGetPaymentMethods();
  }, []);
  if (loading) {
    return (
      <Loader />
    )
  } else if (!order) {
    navigate('/')
  } else {  
    return (
      <section className={styles['checkout']}>
        <h2 className={styles['checkout-header']}>{t('checkout.title')}</h2>
        <div className={styles['checkout-main']}>
          <div className={styles['checkout-details']}>
            <div className={styles['checkout-product-details']}>
              <img className={styles['checkout-product-details-image']} src={order.product?.thumbnail} alt='product name' />
              <div className={styles['checkout-product-details-wrapper']}>
                <div className={styles['checkout-product-details-wheel']}>
                  <p className={styles['checkout-product-details-wheel-text']}>
                    {t('checkout.wheel_number')}:
                    <span className={styles['checkout-product-details-wheel-number']}>{order.product?.wheel_number}</span>
                  </p>
                </div>
                <h3 className={styles['checkout-product-details-header']}>{order.product?.name}</h3>
                <p className={styles['checkout-product-details-tickets']}>
                  {t('checkout.selected_tickets')}
                  {
                    order.wheel_slots &&     
                    <span className={styles['checkout-product-details-tickets-number']}>
                      {order.wheel_slots.join(', ')}
                    </span>
                  }
                </p>
              </div>
            </div>
            {order.wheel_slots &&
              <AutoSlider number_of_tickets={order.wheel_slots?.length}>
                  {order.wheel_slots?.map(num => 
                    <SwiperSlide key={num}>
                      <Ticket number={num} selectedTickets={order.wheel_slots}/>
                    </SwiperSlide>
                  )}
              </AutoSlider>
            }
            <div className={styles['checkout-summary']}>
              <h3 className={styles['checkout-summary-header']}>{t('checkout.payment_summary')}</h3>
              <div className={styles['checkout-summary-content']}>
                <p className={styles['checkout-summary-ticket']}>
                  {t('checkout.ticket_price')}
                  <span className={styles['checkout-summary-ticket-price']}>{order.product?.price_per_product} {t('checkout.per_ticket')}</span>
                </p>
                <p className={styles['checkout-summary-subtotal']}>
                  {t('checkout.subtotal')}
                  <span className={styles['checkout-summary-subtotal-tickets']}>{order.wheel_slots?.length} { order.wheel_slots?.length > 1 ? t('tickets') : t('ticket')}</span>
                </p>
                <div className={styles['checkout-summary-total']}>
                  <p className={styles['checkout-summary-total-text']}>
                    {t('checkout.total')}
                    <span className={styles['checkout-summary-total-hint']}>{t('vat')}</span>
                  </p>
                  <p className={styles['checkout-summary-total-price']}>{order.total_amount} {t('currency')}</p>
                </div>
              </div>
            </div>
            {isMobile &&
              <div className={styles['checkout-payment']}>
              <h3 className={styles['checkout-payment-header']}>{t('checkout.payment_methods')}</h3>
              <div>
                {
                  paymentMethods && paymentMethods.filter(payment => !payment.is_disabled).map((payment) => 
                    <Radio 
                      key={payment.id} 
                      selected={selectedPayment} 
                      handleClick={handlePaymentSelect} 
                      id={payment.id} 
                      name={'payments'}
                      icons={payment.icons}
                      value={payment.slug} 
                      label={payment.slug === 'topup' ? t('balance') : payment.name} 
                    >
                      {profile && payment.slug === 'topup' &&
                        <p className={styles['checkout-payment-balance']}>{profile.available_balance} {t('currency')}</p>
                      }
                    </Radio>
                  )
                }
              </div>
            </div>
            }
            <Button className={styles['checkout-summary-action']} handleSubmit={handleCheckout} text={t('checkout.checkout_button')} type={'button'} />
          </div>
          {!isMobile &&
            <div className={styles['checkout-payment']}>
              <h3 className={styles['checkout-payment-header']}>{t('checkout.payment_methods')}</h3>
              <div>
                {
                  paymentMethods && paymentMethods.filter(payment => !payment.is_disabled).map((payment) => 
                    <Radio 
                      key={payment.id} 
                      selected={selectedPayment} 
                      handleClick={handlePaymentSelect} 
                      id={payment.id} 
                      name={'payments'}
                      icons={payment.icons}
                      value={payment.slug} 
                      label={payment.slug === 'topup' ? t('balance') : payment.name} 
                    >
                      {profile && payment.slug === 'topup' &&
                        <p className={styles['checkout-payment-balance']}>{profile.available_balance} {t('currency')}</p>
                      }
                    </Radio>
                  )
                }
              </div>
            </div>
          }
        </div>
      </section>
    )
  }
}

export default Checkout;
