import styles from './index.module.scss';
import trendingUp from '../../assets/icons/trending-up.svg';
import trendingDown from '../../assets/icons/trending-down.svg';
import { useState } from 'react';
import Button from '../formButton';
import { getSingleWalletHistory } from '../../services/tabServices';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export default function TransactionCard({ transaction }) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const [textToCopy, setTextToCopy] = useState('');
  const dispatch = useDispatch();
  const { t,i18n } = useTranslation();

  const date = new Date(transaction.updated_at);
  
  const handleShowModal = () => {
    handleGetSingleِWalletHistory();
    setShowModal(true);  
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }
  const handleGetSingleِWalletHistory = async () => {
    const response = await getSingleWalletHistory(transaction.id);
    if (response.status === 200) {
      setData(response.data);
      setTextToCopy(response.data.voucher?.voucher_code);
    }
  }
  const onCopyText = () => {
    dispatch(setSnackbar({
      display: true,
      variant: 'success',
      message: t('code_copied')
    }))
  }
  console.log(transaction.details)
  const getMonths = (month) => {
    switch (month) {
      case 0:
        return t('january');
      case 1:
        return t('february');
      case 2:
        return t('march');
      case 3:
        return t('april');
      case 4:
        return t('may');
      case 5:
        return t('june')
      case 6:
        return t('july');
      case 7:
        return t('august');
      case 8:
        return t('september');
      case 9:
        return t('october');
      case 10:
        return t('november');
      case 11:
        return t('december');
      default:
        return ''
    }
  }
  return (
    <>
      <div className={styles['card']} onClick={handleShowModal}>
        <img className={styles['card-icon']} src={!transaction.voucher ? trendingUp : trendingDown} alt='' />
        <div className={styles['card-product']}>
          <h3 className={styles['card-product-title']}>{!transaction.voucher ? transaction.wheel?.product.name : transaction.details}</h3>
          <p className={styles['card-product-date']}>{`${getMonths(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}, ${date.getHours() > 12 ? (date.getHours() - 12).toLocaleString('en-US',{minimumIntegerDigits:2}) : date.getHours().toLocaleString('en-US',{minimumIntegerDigits:2})}:${date.getMinutes().toLocaleString('en-US',{minimumIntegerDigits:2})} ${date.getHours() >= 12 ? t('pm') : t('am')}`}</p>
        </div>
        <p className={`${styles['card-amount']} ${!transaction.voucher ? styles['up']:styles['down']}`}>{Number(transaction.amount).toFixed(0)} {t('currency')}</p>
      </div>
      {showModal && data &&
        <div className={styles['modal']}>
          <div className={styles['modal-container']}>
            <img className={styles['modal-icon']} src={!data.voucher ? trendingUp : trendingDown} alt='' />
            <div className={styles['modal-content']}>
              <p className={styles['modal-header']}>{i18n.language === 'ar-EG' && transaction.voucher && t('my_profile.details')} {data.details} {i18n.language === 'en-US' && transaction.voucher && t('my_profile.details')}</p>
              <ul className={styles['modal-list']}>
                {!data.voucher ?
                  <>
                    <li className={styles['modal-list-item']}>{t('my_profile.round_number')} <span>{data.wheel.product.wheel_number}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.transaction_date')} <span>{`${new Date(data.created_at).getDate()}/${new Date(data.created_at).getMonth() + 1}/${new Date(data.created_at).getFullYear()}, ${new Date(data.created_at).getHours() > 12 ? new Date(data.created_at).getHours() - 12 : new Date(data.created_at).getHours()}:${new Date(data.created_at).getMinutes()} ${new Date(data.created_at).getHours() >= 12 ? t('pm') : t('am')}`}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.no_of_tickets')} <span>{data.wheel.num_of_tickets}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.transaction_status')} <span>{t('my_profile.done_successfully')}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.user_level')} <span>{data.current_level}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.ticket_numbers')} <span>{data.wheel.ticket_number.slots.join(', ')}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.ticket_price')} <span>{data.wheel.product.price_per_product} {t('currency')}</span></li>
                    <li className={styles['modal-list-item']}>
                      <p className={styles['modal-list-item-text']}>
                        {t('my_profile.discount-won')}
                        <span>( {t('my_profile.no_of_tickets')} * {t('my_profile.ticket_price')} * {t('my_profile.user_level')} )</span>
                      </p>
                      <span>{data.amount} {t('currency')}</span>
                    </li>
                  </> :
                  <>
                    <li className={styles['modal-list-item']}>{t('my_profile.vendor')} <span>{data.voucher.vendor}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.transaction_date')} <span>{`${new Date(data.created_at).getDate()}/${new Date(data.created_at).getMonth() + 1}/${new Date(data.created_at).getFullYear()}, ${new Date(data.created_at).getHours() > 12 ? new Date(data.created_at).getHours() - 12 : new Date(data.created_at).getHours()}:${new Date(data.created_at).getMinutes()} ${new Date(data.created_at).getHours() >= 12 ? t('pm') : t('am')}`}</span></li>
                    <li className={styles['modal-list-item']}>{t('my_profile.discount_amount')} <span>{data.amount} {t('currency')}</span></li>
                    <li className={[styles['modal-list-item']]}>{t('my_profile.discount_code')}
                      <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                        <span className={styles['code']}>
                          {data.voucher.voucher_code}
                        </span>
                      </CopyToClipboard>
                    </li>
                    <li className={styles['modal-list-item']}></li>
                  </>
                }
              </ul>
              <Button handleSubmit={handleCloseModal} text={t('my_profile.done_button')} type={'button'} />
            </div>
          </div>
        </div>
      }
    </>
  )
}
