import styles from './index.module.scss';
import Button from '../formButton';
import ticket from '../../assets/icons/ticket.svg';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export function PaymentSuccess({wheel,isTopUp,amount,wheelId,ticketNumber}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStartWheel = () => {
    if (wheel) {
      navigate(`/live-wheel/${wheel}`)
    } else {
      navigate(`/live-wheel/${wheelId}`)
    }
  };
  const handleHomeNavigation = () => {
    navigate('/')
  };
  const handleCategoryNavigation = () => {
    navigate('/category/all')
  };
  
  return (
    <div className={styles['success']}>
      <div className={styles['success-main']}>
        <img src={ticket} className={styles['success-image']} alt='ticket' />
        <div className={styles['success-content']}>
          <h3 className={styles['success-content-header']}>{t('payment_success.title')}</h3>
          {isTopUp ?
            <p className={styles['success-content-text']}>{t('payment_success.amazing')} {amount} {t('currency')} {t('payment_success.added')}<span>{t('payment_success.buy')}</span></p> :
            <p className={styles['success-content-text']}>{t('payment_success.amazing')} {ticketNumber} {ticketNumber > 1 ? t('tickets') : t('ticket')}.<span>{t('payment_success.spin_now')}</span></p>
          }
        </div>
      </div>
      <div className={styles['success-actions']}>
        {isTopUp ? 
          <Button handleSubmit={handleCategoryNavigation} variant={'gradient'} text={t('payment_success.buy_button')}/>:
          <Button handleSubmit={handleStartWheel} variant={'gradient'} text={t('payment_success.spin_button')}/>
        }
        <Button handleSubmit={handleHomeNavigation} text={t('payment_success.back_button')} variant={'outline'}/>
      </div>
    </div>
  )
}

export default PaymentSuccess;